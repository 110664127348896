<template>
    <div class="main-communicate">
        <div style="width:100%;position:relative;">
            <Header :curActive="4"></Header>
            <img class="main-communicate-bg" src="../../assets/imgs/bg2.png" alt="">
            <div class="main_centerbox">
                <div class="main_l_box">
                    <h3 class="title">培训咨询</h3>
                    <el-menu
                        :unique-opened="true"
                        :default-active="defaultId"
                        class="el-menu-vertical-demo"
                        @open="handleOpen"
                        @close="handleClose"
                        @select="selectOneNav"
                        background-color="#fff"
                        text-color="#333"
                        active-text-color="#1877C9">
                        <div v-for="(item,i) in nav" :key="i">

                            <el-submenu v-if="(item.childrenList != null && item.childrenList.length > 0)" :index="item.id" style="border-bottom:1px solid #F3F3F3;font-size:16px!important;">
                                <template slot="title" style="border-bottom:1px solid #F3F3F3;">
                                    <span>{{item.categoryTitle}}</span>
                                </template>
                                <div v-for="(item2,j) in item.childrenList" :key="j">
                                    
                                    <el-submenu v-if="(item2.childrenList != null && item2.childrenList.length > 0)" :index="item2.id" style="border-bottom:1px solid #F3F3F3;font-size:16px!important;">
                                        <template slot="title">{{item2.categoryTitle}}</template>
                                        <!-- <el-menu-item v-for="(item3,k) in item2.childrenList" :key="k" :index="item3.id">{{item3.categoryTitle}}</el-menu-item> -->
                                        <div v-for="(item3,j) in item2.childrenList" :key="j">
                                    
                                            <el-submenu v-if="(item3.childrenList != null && item3.childrenList.length > 0)" :index="item3.id" style="border-bottom:1px solid #F3F3F3;font-size:16px!important;">
                                                <template slot="title">{{item3.categoryTitle}}</template>
                                                <el-menu-item v-for="(item4,k) in item3.childrenList" :key="k" :index="item4.id">{{item4.categoryTitle}}</el-menu-item>
                                            </el-submenu>
                                            <el-menu-item v-if="item3.childrenList == null" :index="item3.id" style="border-bottom:1px solid #F3F3F3;font-size:16px!important;">
                                                <span slot="title">{{item3.categoryTitle}}</span>
                                            </el-menu-item>
                                        </div>
                                    </el-submenu>
                                    <el-menu-item v-if="item2.childrenList == null" :index="item2.id" style="border-bottom:1px solid #F3F3F3;font-size:16px!important;">
                                        <span slot="title">{{item2.categoryTitle}}</span>
                                    </el-menu-item>
                                </div>
                            </el-submenu>
                            <el-menu-item v-if="item.childrenList == null" :index="item.id" style="border-bottom:1px solid #F3F3F3;font-size:16px!important;">
                                <span slot="title">{{item.categoryTitle}}</span>
                            </el-menu-item>
                        </div>
                    </el-menu>
                </div>
                <div class="main_r_box">
                    <div class="position_box">
                        <span class="span">当前位置：</span>
                        <el-breadcrumb separator-class="el-icon-arrow-right" style="display: inline-block;height: 35px;line-height: 35px;float: left;">
                            <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                            <el-breadcrumb-item>培训咨询</el-breadcrumb-item>
                            <el-breadcrumb-item>{{curTitle}}</el-breadcrumb-item> -->
                            <el-breadcrumb-item v-for="(item,i) in curTitle" :key="i">{{item}}</el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                    <!-- 子栏目列表 -->
                    <div v-if="ifShow.index2" class="main_r_info_box">
                        <ul class="main_r_info_box_ul2">
                            <li v-for="(item,i) in subNav" :key="i">
                                <a @click="clickMenuItemFn(item.id)" href="javascript:void(0);" style="display:block;position:relative;height: 100px;">
                                    <div class="main_r_info_box_li_imgbox2">
                                        <img class="main_img2" :src="(item.imgSrc && item.imgSrc.length > 0) ? imgBase + item.imgSrc[0] : defaultImg1" alt="">
                                    </div>
                                    <h4 class="main_r_info_box_li_h42">{{item.categoryTitle ? item.categoryTitle : ''}}</h4>
                                    <p class="main_r_info_box_li_time2">{{item.categoryDescrip ? item.categoryDescrip : ''}}</p>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!-- 组件集合 -->
                    <ImgInfoList v-if="ifShow.index1" :prtitle="'培训咨询'" :currentId="currentId" @titleInfo="titleInfo"></ImgInfoList>
                </div>
            </div>
            <Footer></Footer>
        </div>
        <!-- 回到顶部 -->
        <el-backtop target=".main-communicate" :bottom="100">
            <!-- <div
                style="{
                    height: 100%;
                    width: 100%;
                    background-color: #f2f5f6;
                    box-shadow: 0 0 6px rgba(0,0,0, .12);
                    text-align: center;
                    line-height: 40px;
                    color: #1989fa;
                }"
            >
                UP
            </div> -->
            <i class="el-icon-caret-top"></i>
        </el-backtop>
    </div>
</template>

<script>

import $ from 'jquery';
import api from "@/../static/api/api.js";

import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';
import ImgInfoList from '@/views/consultation/imgInfoList.vue';

import defaultImg1 from '@/assets/imgs/default.png'
import defaultImg2 from '@/assets/imgs/default2.png'
import defaultImg3 from '@/assets/imgs/default3.png'

export default {
    name: 'consultation',
    data() {
        return {
            imgBase: this.$store.state.imgBase,
            defaultImg1: defaultImg1,//轮播图默认图片
            defaultImg2: defaultImg2,//研究项目默认图
            defaultImg3: defaultImg3,//交流传播默认图
            nav: [],//左侧栏目导航数据
            subNav: [],//子栏目数据
            currentId: '',//默认展示外援培训
            curTitle: [],//当前位置里显示的栏目名称
            defaultId: '',
            ifShow: {
                index1: false,
                index2: false, //子栏目列表
            }
        }
    },
    components:{
        Header,
        Footer,
        ImgInfoList
    },
    mounted() {
        document.title = '培训咨询 | 知识中心'
        this.fetchNavData();
    },
    methods: {
        //获取左侧栏目列表函数
        fetchNavData(){
            let _this = this;

            let menuId1 = '1470218300600979457';
            let onlyChild1 = false;
            let paltformId1 = '';
            _this.ifShow.index1 = false;
            api.getTopMenuList(menuId1, onlyChild1, paltformId1).then(res => {
                if (res.data && res.data.childrenList.length > 0) {
                    _this.nav = res.data.childrenList;
                    
                    let tmpId = '';
                    if(res.data.childrenList[0].childrenList && res.data.childrenList[0].childrenList.length > 0){
                        tmpId = res.data.childrenList[0].childrenList[0].id;
                    }else{
                        tmpId = res.data.childrenList[0].id;
                    }
                    _this.defaultId = _this.getQueryString('menuId') ? _this.getQueryString('menuId') : tmpId;
                    _this.currentId = _this.getQueryString('menuId') ? _this.getQueryString('menuId') : tmpId;
                    _this.fetchUnderData(_this.defaultId);
                }
            });
        },
        //获取子栏目列表函数
        fetchUnderData(indexPath, num = 1){
            let _this = this;
            
            let menuId1 = indexPath;
            // let menuId1 = '1470217322384429057';
            let onlyChild1 = false;
            let paltformId1 = '';
            api.getUnderMenuList(menuId1, onlyChild1, paltformId1).then(res => {
                console.log(res.data,'res')
                _this.subNav = [];
                if (res.data.childrenList && res.data.childrenList.length > 0) {
                   
                    _this.subNav = res.data.childrenList;
                    _this.ifShow.index1 = false;
                    _this.ifShow.index2 = true;
                    _this.curTitle = res.data.categoryPosition;
                }else{
                    if(num == 2){
                        _this.defaultId = indexPath;
                        _this.currentId = indexPath;
                    }
                    _this.ifShow.index1 = true;
                    _this.ifShow.index2 = false;
                }
            });
        },
        //点击子栏目列表函数，
        //还有子栏目就跳转新的子栏目列表，
        //没有就跳转文章列表
        clickMenuItemFn(indexPath){
            //点击根据栏目选择不同的右侧组件展示
            this.fetchUnderData(indexPath, 2);
        },
        //选中某一个左侧栏目index就是栏目id
        selectOneNav(index, indexPath){
            let _this = this;
            console.log(index, indexPath,'test');
            _this.currentId = index;
            this.fetchUnderData(indexPath);
        },
        //左侧栏目展开
        handleOpen(key, keyPath) {
            console.log(key, keyPath, 1);
        },
        //左侧栏目合上
        handleClose(key, keyPath) {
            console.log(key, keyPath, 2);
        },
        //从子组件获取当前栏目名称
        titleInfo(data){
            this.curTitle = data;
        },
        //获取URL参数
        getQueryString(key){
            var reg = new RegExp("(^|&)"+key+"=([^&]*)(&|$)");
            var result = window.location.search.substr(1).match(reg);
            return result?decodeURIComponent(result[2]):null;
        }
    }
}
</script>

<style scoped>
.main-communicate{
    width:100%;
    height: 100vh;
    overflow-x:hidden;
    position: relative;
}
.main-communicate-bg{
    position: absolute;
    bottom:273px;
    left:0;
    z-index: -1;
}
.main_centerbox{
    width: 1200px;
    margin: 28px auto 0;
    overflow: hidden;
}
/* 左侧导航 */
.main_l_box{
    width: 253px;
    background: #FFFFFF;
    float: left;
    /* max-height: 975px; */
    /* overflow-y: scroll; */
    /* overflow-x: hidden; */
}
.main_l_box .title{
    width: 253px;
    height: 48px;
    /* background: linear-gradient(90deg, #2164C8, #1B99C6); */
    background: #217ad5;
    font-size: 18px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #F9F9F9;
    line-height: 48px;
    text-align: center;
}
/* 右侧部分 */
.main_r_box{
    width:890px;
    min-height: 700px;
    float: right;
}
.position_box{
    width: 100%;
    height: 35px;
    line-height: 35px;
    background: #F9F9F9;
}
.position_box .span{
    height: 35px;
    line-height: 35px;
    display: inline-block;
    float: left;
    padding-left: 12px;
    margin-right:10px;
}
.main_r_info_box{
    width:100%;
    margin-top:38px;
}
.main_r_info_box_ul{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.main_r_info_box_ul li{
    width: 274px;
    height:278px;
    overflow: hidden;
    float: left;
    margin-right: 34px;
    margin-bottom: 35px;
}
.main_r_info_box_ul li:nth-child(3){
    margin-right: 0;
}
.main_r_info_box_ul li:nth-child(6){
    margin-right: 0;
}
.main_r_info_box_ul li:nth-child(9){
    margin-right: 0;
}
.main_r_info_box_li_imgbox{
    width: 100%;
    height: 162px;
    overflow: hidden;
    position: relative;
}
.main_r_info_box_li_imgbox .main_img{
    width:100%;
    display: block;
}
.main_r_info_box_li_imgbox .video_btn{
    width: 30px;
    height: 30px;
    position: absolute;
    top:66px;
    left:122px;
    display: none;
    cursor: pointer;
}
.main_r_info_box_li_time{
    width: 100%;
    height: 12px;
    font-size: 12px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #1B70CB;
    line-height: 12px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 21px;
}
.main_r_info_box_li_h4{
    width: 100%;
    height: 49px;
    font-size: 18px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #000000;
    line-height: 32px;
    text-align: left;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.el-menu {
    border-right: solid 1px #e6e6e6;
    list-style: none;
    position: relative;
    margin: 0;
    padding-left: 0;
    background-color: #FFF;
    border-left: 1px solid #e6e6e6!important;
}
/* 分页 */
.page_box{
    width:100%;
    height:63px;
    margin-top:30px;
    margin-bottom:100px;
}





</style>
